import service from "../service.js";

export const serchDeviceChgLog = params => {
    return service({
      method: 'POST',
      url: '/deviceChg/serchDeviceChgLog',
      params
    })
}

export const chgDeviceLocal = (data) => {
    return service({
      method: 'POST',
      url: '/deviceChg/chgDeviceLocal',
      data
    })
}

export const queryChgLocalInfo = (id) => {
    return service({
      method: 'GET',
      url: '/deviceChg/queryChgLocalInfo',
      params: {
        id
      }
    })
  }